var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg_gray" }),
    _c(
      "div",
      { staticClass: "centerbox" },
      [
        _c(
          "van-tabs",
          { staticClass: "tab_title", on: { click: _vm.getlist } },
          [
            _c("van-tab", { attrs: { title: "全部" } }, [
              _vm.isloaddata
                ? _c(
                    "div",
                    _vm._l(_vm.listdata.content, function(items, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "system_msg" },
                        [
                          _c("div", { staticClass: "msgbox" }, [
                            _c("h2", { staticClass: "flex-between h2" }, [
                              _c("div", [
                                _vm._v(
                                  " 顺丰单号：" +
                                    _vm._s(
                                      items.expressNo ? items.expressNo : "-"
                                    ) +
                                    " "
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "cityflex rel" }, [
                              _c("img", {
                                staticClass: "arrowimg",
                                attrs: { src: _vm.arrowicon, alt: "" }
                              }),
                              _c("div", [
                                _vm._v(
                                  " " + _vm._s(items.fromCity || " 未知 ") + " "
                                )
                              ]),
                              _c("b", { staticClass: "arrowbox" }, [
                                _c("i", [
                                  _vm._v(
                                    _vm._s(
                                      items.status == "TRANSPORT"
                                        ? "运输中"
                                        : items.status == "WAIT_SEND"
                                        ? "待取件"
                                        : items.status == "REFUSE"
                                        ? "已拒绝"
                                        : items.status == "CANCEL"
                                        ? "已取消"
                                        : items.status == "SIGNED"
                                        ? "已签收"
                                        : "待审核"
                                    )
                                  )
                                ])
                              ]),
                              _c("div", [
                                _vm._v(" " + _vm._s(items.toCity) + " ")
                              ])
                            ]),
                            _c("div", { staticClass: "flex" }, [
                              _c("div", [_vm._v("下单时间：")]),
                              items.expressTime
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate_")(items.expressTime)
                                      )
                                    )
                                  ])
                                : items.userOrderTime
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate_")(
                                          items.userOrderTime
                                        )
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("div", { staticClass: "flexs endflex" }, [
                              _c(
                                "div",
                                { staticClass: "canclebox" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "logisticsDetails",
                                          query: { id: items.orderExpressNo }
                                        }
                                      }
                                    },
                                    [_vm._v("物流详情")]
                                  )
                                ],
                                1
                              ),
                              items.status === "WAIT_SEND"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "canclebox",
                                      on: {
                                        click: function($event) {
                                          return _vm.cancelOrder(
                                            items.orderExpressNo
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("取消")]
                                  )
                                : _vm._e(),
                              items.status === "SIGNED" ||
                              items.status === "CANCEL"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "canclebox",
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteOrder(
                                            items.orderExpressNo
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    [
                      _c("van-empty", {
                        staticClass: "custom-image",
                        attrs: {
                          image: require("@/assets/images/pic_no information@2x.png"),
                          description: "暂无数据!"
                        }
                      })
                    ],
                    1
                  )
            ]),
            _c("van-tab", { attrs: { title: "待取件" } }, [
              _vm.isloaddata
                ? _c(
                    "div",
                    _vm._l(_vm.listdata.content, function(items, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "system_msg" },
                        [
                          _c("div", { staticClass: "msgbox" }, [
                            _c("h2", { staticClass: "flex-between h2" }, [
                              _c("div", [
                                _vm._v(
                                  " 顺丰单号：" +
                                    _vm._s(
                                      items.expressNo ? items.expressNo : "-"
                                    ) +
                                    " "
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "cityflex" }, [
                              _c("div", [
                                _vm._v(" " + _vm._s(items.fromCity) + " ")
                              ]),
                              _c("b", { staticClass: "arrowbox" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/icon_yunsong.png"),
                                    alt: ""
                                  }
                                }),
                                _c("i", [_vm._v("待取件")])
                              ]),
                              _c("div", [
                                _vm._v(" " + _vm._s(items.toCity) + " ")
                              ])
                            ]),
                            _c("div", { staticClass: "flex" }, [
                              _c("div", [_vm._v("下单时间：")]),
                              items.expressTime
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate_")(items.expressTime)
                                      )
                                    )
                                  ])
                                : items.userOrderTime
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate_")(
                                          items.userOrderTime
                                        )
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("div", { staticClass: "flexs endflex" }, [
                              _c(
                                "div",
                                { staticClass: "canclebox" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "logisticsDetails",
                                          query: { id: items.orderExpressNo }
                                        }
                                      }
                                    },
                                    [_vm._v("物流详情")]
                                  )
                                ],
                                1
                              ),
                              items.status === "WAIT_SEND"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "canclebox",
                                      on: {
                                        click: function($event) {
                                          return _vm.cancelOrder(
                                            items.orderExpressNo
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("取消")]
                                  )
                                : _vm._e(),
                              items.status === "SIGNED" ||
                              items.status === "CANCEL"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "canclebox",
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteOrder(
                                            items.orderExpressNo
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    [
                      _c("van-empty", {
                        staticClass: "custom-image",
                        attrs: {
                          image: require("@/assets/images/pic_no information@2x.png"),
                          description: "暂无数据!"
                        }
                      })
                    ],
                    1
                  )
            ]),
            _c("van-tab", { attrs: { title: "运输中" } }, [
              _vm.isloaddata
                ? _c(
                    "div",
                    _vm._l(_vm.listdata.content, function(items, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "system_msg" },
                        [
                          _c("div", { staticClass: "msgbox" }, [
                            _c("h2", { staticClass: "flex-between h2" }, [
                              _c("div", [
                                _vm._v(
                                  " 顺丰单号：" +
                                    _vm._s(
                                      items.expressNo ? items.expressNo : "-"
                                    ) +
                                    " "
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "cityflex" }, [
                              _c("div", [
                                _vm._v(" " + _vm._s(items.fromCity) + " ")
                              ]),
                              _c("b", { staticClass: "arrowbox" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/arrownpx.png"),
                                    alt: ""
                                  }
                                }),
                                _c("i", [_vm._v("运输中")])
                              ]),
                              _c("div", [
                                _vm._v(" " + _vm._s(items.toCity) + " ")
                              ])
                            ]),
                            _c("div", { staticClass: "flex" }, [
                              _c("div", [_vm._v("下单时间：")]),
                              items.expressTime
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate_")(items.expressTime)
                                      )
                                    )
                                  ])
                                : items.userOrderTime
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate_")(
                                          items.userOrderTime
                                        )
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("div", { staticClass: "flexs endflex" }, [
                              _c(
                                "div",
                                { staticClass: "canclebox" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "logisticsDetails",
                                          query: { id: items.orderExpressNo }
                                        }
                                      }
                                    },
                                    [_vm._v("物流详情")]
                                  )
                                ],
                                1
                              ),
                              items.status === "WAIT_SEND"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "canclebox",
                                      on: {
                                        click: function($event) {
                                          return _vm.cancelOrder(
                                            items.orderExpressNo
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("取消")]
                                  )
                                : _vm._e(),
                              items.status === "SIGNED" ||
                              items.status === "CANCEL"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "canclebox",
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteOrder(
                                            items.orderExpressNo
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    [
                      _c("van-empty", {
                        staticClass: "custom-image",
                        attrs: {
                          image: require("@/assets/images/pic_no information@2x.png"),
                          description: "暂无数据!"
                        }
                      })
                    ],
                    1
                  )
            ]),
            _c("van-tab", { attrs: { title: "已签收" } }, [
              _vm.isloaddata
                ? _c(
                    "div",
                    _vm._l(_vm.listdata.content, function(items, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "system_msg" },
                        [
                          _c("div", { staticClass: "msgbox" }, [
                            _c("h2", { staticClass: "flex-between h2" }, [
                              _c("div", [
                                _vm._v(
                                  " 顺丰单号：" +
                                    _vm._s(
                                      items.expressNo ? items.expressNo : "-"
                                    ) +
                                    " "
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "cityflex" }, [
                              _c("div", [
                                _vm._v(" " + _vm._s(items.fromCity) + " ")
                              ]),
                              _c("b", { staticClass: "arrowbox" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/arrownpx.png"),
                                    alt: ""
                                  }
                                }),
                                _c("i", [_vm._v("已签收")])
                              ]),
                              _c("div", [
                                _vm._v(" " + _vm._s(items.toCity) + " ")
                              ])
                            ]),
                            _c("div", { staticClass: "flex" }, [
                              _c("div", [_vm._v("下单时间：")]),
                              items.expressTime
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate_")(items.expressTime)
                                      )
                                    )
                                  ])
                                : items.userOrderTime
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate_")(
                                          items.userOrderTime
                                        )
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("div", { staticClass: "flexs endflex" }, [
                              _c(
                                "div",
                                { staticClass: "canclebox" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "logisticsDetails",
                                          query: { id: items.orderExpressNo }
                                        }
                                      }
                                    },
                                    [_vm._v("物流详情")]
                                  )
                                ],
                                1
                              ),
                              items.status === "WAIT_SEND"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "canclebox",
                                      on: {
                                        click: function($event) {
                                          return _vm.cancelOrder(
                                            items.orderExpressNo
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("取消")]
                                  )
                                : _vm._e(),
                              items.status === "SIGNED" ||
                              items.status === "CANCEL"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "canclebox",
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteOrder(
                                            items.orderExpressNo
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    [
                      _c("van-empty", {
                        staticClass: "custom-image",
                        attrs: {
                          image: require("@/assets/images/pic_no information@2x.png"),
                          description: "暂无数据!"
                        }
                      })
                    ],
                    1
                  )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }